const mern = [
    {
      subject: "MERN Full Stack",
      detail1: "Learn how to build full stack app from scratch.",
      detail2: "Master the entire modern full-stack: Node, Express, React, MongoDB and Mongoose.",
      detail3: "How to connect ReactJS with NodeJS, Express & MongoDB.",
      detail4: "Becoming proficient in integrating RESTful APIs with React.",
      
    },
    
    
  

  ];

export default mern