const frontend = [
    {
      subject: "HTML",
      detail1: "Introduction to HTML.",
      detail2: "HTML Boilerplate.",
      detail3: "Structuring Text in HTML.",
      detail4: "HTML Lists.",
      detail5: "HTML Image Elements.",
      detail6: "HTML Link and Anchor Tags.",
      detail7: "Building HTML site Step-by-step.",
      detail8: "HTML Tables.",
      detail9: "HTML Forms.",
    },
    {
      subject: "CSS",
      detail1: "Inline CSS,Internal CSS, External CSS",
      detail2: "CSS Selectors.",
      detail3: "Classes Vs Ids.",
      detail4: "Enhancing the appearance of HTML site with CSS Step-by-step.",
      detail5: "CSS Display property.",
      detail6: "Font styling.",
      detail7: "Typography.",
      detail8: "CSS Sizing.",
      
    },

    {
      subject: "Bootstrap",
      detail1: "Introduction to Bootstrap",
      detail2: "Bootstrap Navigation Bar.",
      detail3: "Bootstrap Containers.",
      detail4: "Bootstrap Cards.",
      detail5: "Bootstrap Buttons & Font Awesomes.",
      detail6: "Building another HTML site with Bootstrap Step-by-step.",
     
      
    },

    {
      subject: "Javascript",
      detail1: "Introduction to Javascript.",
      detail2: "Javascript Variables.",
      detail3: "Data types String, Number, Boolean, null, undefined.",
      detail4: "Template String.",
      detail5: "String Properties.",
      detail6: "Arrays and its methods.",
      detail7: "Objects.",
      detail8: "While loop, Conditional, Functions.",
      detail9: "Arrow Functions.",
    },
    
    {
      subject: "Document Object Model (DOM)",
      detail1: "getElementById.",
      detail2: "getElementsByClassName.",
      detail3: "querySelector.",
      detail4: "getElementsByTagName.",
      detail5: "querySelectorAll - Filtering.",
      detail6: "For Loop.",
      detail7: "textContent.",
      detail8: "InnerHTML.",
      detail9: "elements Array - Accessing using index.",
    },

    {
      subject: "Javascript - Adding Behaviours to website",
      detail1: "Alert.",
      detail2: "Using constants and variables.",
      detail3: "Defining Functions.",
      detail4: "Adding event listner to HTML elements.",
      detail5: "Getting data from user.",
      detail6: "Create array and push data.",
      
    },

    {
        subject: "Flex Box and Grid",
        detail1: "Introduction to Flex Box.",
        detail2: "Understand Flex Display, Flex Direction and Flex Layout.",
        detail3: "How to align and justify the layout of children items.",
        detail4: "Introduction to Grid.",
        detail5: "How grid size is don and how to create arrange a grid.",
        detail6: "Understand how to combine both grid and flex.",
        
      },
      {
        subject: "REACT JS",
        detail1: "Introduction to React and its basic concepts.",
        detail2: "Learn front-end development with React.",
        detail3: "Learn to pass Props and work with them.",
        detail4: "Learn what is JSX and how it works behind the scenes.",
        detail5: "Learn about the React DOM.",
        detail6: "Learn about React Hooks.",
        detail7: "Manage complex state efficiently with React's Context API & React Redux.",
        detail8: "Learn about routing & route-related data fetching with React Router.",
        detail9: "Creating dynamic websites with help of re-usable components.",
      },
  

  ];

export default frontend