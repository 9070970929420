import React from "react";
import { Row, Table } from "react-bootstrap";
import experience from '../data/experience'
// import "../assets/css/Profile.css";
// import './Table.css';

export const OrganizationalExperience = () => {

    return (
        <>
        <Row>
    <div className="col-2"></div>
    <div className="col-8">
          <h3 className="text-warning text-center">Organizational Experience</h3>
          <br></br>
    
          <Table striped bordered hover responsive mt-1 py-3 variant="dark"    className='table-style,table-sm width: 50%'>
            <thead>
              <tr>
                <th>Tenure</th>
                <th>Company Name</th>
              </tr>
            </thead>
            <tbody>
              {experience?.map((exp) => (
                <tr key={exp.id}>
                  <td>{exp.Tenure}</td>
                  <td>{exp.CompanyName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
          </Row>
    
        </>
      )
    };




export default OrganizationalExperience;