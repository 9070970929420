import React from "react";
import { Row, Table } from "react-bootstrap";
import qualification from '../data/qualification'
// import "../assets/css/Profile.css";
// import './Table.css';

export const AcademicQualification = () => {

    return (
        <>
        <Row>
    <div className="col-2"></div>
    <div className="col-8">
          <h3 className="text-warning text-center">Academic Qualification</h3>
          <br></br>
    
          <Table striped bordered hover responsive mb-5 size="sm"  className='table-style,table-sm width: 50%'>
            <thead>
              <tr>
                <th>Degree</th>
                <th>Institution</th>
                <th>Year</th>
              </tr>
            </thead>
            <tbody>
              {qualification?.map((q) => (
                <tr key={q.id}>
                  <td>{q.Degree}</td>
                  <td>{q.Institution}</td>
                  <td>{q.Year}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
          </Row>
    
        </>
      )
    };




export default AcademicQualification;