import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import  ServiceCard  from "./ServiceCard";
import rShiny from '../data/rShiny'


// import 'animate.css';
import TrackVisibility from 'react-on-screen';

const Services = () => {

  
  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2 className="text-light text-center ">Web Application Development Services</h2>
               
                <hr/>
                  <h6 className="text-light text-center">Our web application development team develops websites and web applications to meet the software requirements of your business.</h6>
                  <h6 className="text-light text-center">Our data anlytics application development team design and develop data analytics web application with R Shiny Technology. </h6>
                <hr/>
                <h6 className="text-light text-center">WEB APPLICATIONS</h6>
                <p className="text-light ">A business needs to have a platform that 
                accurately represents who they are and why people should buy their products or use their services.
                </p>
                <br></br>
                <h6 className="text-light text-center">DATA ANALYTICS WEB APPLICATIONS</h6>
                <p className="text-light ">R Shiny is a web application framework that allows you to build interactive dashboards, data visualizations, and analytical tools using the R programming language.
                </p>
                <br></br>
               
                <hr/>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Web Applications</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">R Shiny Data Analytics</Nav.Link>
                    </Nav.Item>
 

                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                      <h5 className="text-light text-center">Static Website.</h5>
                      <p className="text-light ">A small business needs a website to drive business in today’s digital world.</p>
                      <p className="text-light ">It shows the business actually exists.</p>
                      <p className="text-light ">Consumers will be able to learn anything about the products or services the business is offering.</p>
                      <p className="text-light ">A website can provide many benefits for businesses that want to establish an online presence, reach a wider audience, and increase their sales.</p>
                      <hr></hr>
                      <p className="text-light text-center">By carefully crafting your website, you can make it a powerful asset for your business. It’s like having a storefront.</p>
                      <hr/>
                      <h5 className="text-light text-center">WEB Application.</h5>
                      <p className="text-light ">Web applications can offer valuable services that will be available 24/7 and in any location.</p>
                      <p className="text-light ">This means that you can provide all the benefits of your product or service 
                      without having physical outlets in other countries, states, or even cities.</p>
                      <p className="text-light ">This is especially beneficial for small firms or startups that may lack the capacity to develop a physical presence in various areas.</p>
                      <hr/>
                      <p className="text-light ">A web application helps businesses to target and reach potential and existing customers easily and quickly.</p>
                      <p className="text-light ">Many businesses use these programs to communicate with customers, sell products and improve work processes.</p>
                      <p className="text-light ">A custom web application is made specifically to your business needs, it’s completely flexible and scalable to your business’s demands and growth.</p>
                      <hr></hr>
                      <h6 className="text-light text-center">We develop WEB Application that is customized to meet your business requirements.</h6>
                       
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                      <h5 className="text-light text-center">R Shiny - Data Analytics WEB Application</h5>
                      <p className="text-light ">R Shiny is a web application framework that allows the user to build interactive dashboards, data visualizations, and analytical tools using the R programming language.</p>
                      <hr></hr>
                      <hr></hr>
                      <h5 className="text-light text-center">Development of R Shiny Web Applications</h5>
                      <h5 className="text-light text-center">in various industries</h5>
                      <hr></hr>
                      {
                          rShiny.map((project, index) => {
                            return (
                              <Col sm={12} md={12} lg={12} xl={12}>
                              <ServiceCard
                                key={index}
                                {...project}
                                />
                                </Col>
                            )
                          })
                        }
                      
                      <hr></hr>
                      <h6 className="text-light text-center">We develop R Shiny Data Analytics Web Application that allows for real-time interactivity and dynamic visualization based on user inputs or changes in data.</h6>
                       
                      </Row>
                    </Tab.Pane>
 
                   </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img className="background-image-right" src={colorSharp2}></img> */}
    </section>
  )
}

export default Services;
