import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import  ProjectCard  from "./ProjectCard";
// import 'animate.css';
import TrackVisibility from 'react-on-screen';

const Projects = () => {

  const synopsis = [
    {
      description: "Total IT experience of 25 years.",
    },

    {
      description: "Two years of US onsite Experience.",
    },

    {
      description: "R and Python for ETL,  data science and data mining - Power BI and Qlick sense for visualization and Interactive dashboard & Report.",
    },

    {
      description: "Coming up with innovative ideas of developing projects with new technologies for the users ranging from customers to senior management.",
    },

    {
      description: "With the available data within organization and relevant data outside the organization, ETL and cleaning process will be done with the help of ETL tool, R or Python and results can be displayed in power BI dashboards for the business management and users.",
    },

    {
      description: "Enthusiastic in learning new technologies.",
    },

    {
      description: "Providing new insights into business mission through the use of advanced statistical analysis, datamining and data visualizing technologies to create solutions that enable enhanced business performance.",
    },


  ];

  const technical = [
    {
      description: "Knowledge of SQL language and cloud-based technologies.",
    },

    {
      description: "Data warehousing concepts, data modeling, metadata management.",
    },

    {
      description: "Data lakes, multi-dimensional models, data dictionaries.",
    },

    {
      description: "Performance tuning and setting up resource monitors.",
    },

    {
      description: "Ability to build analytical solutions and models.",
    },

    {
      description: "Coding in languages like Python, R, JavaScript.",
    },

    {
      description: "Root cause analysis of models with solutions.",
    },

    {
      description: "Managing sets of JSON, and CSV from disparate sources.",
    },

    {
      description: "SQL-based databases like Oracle SQL Server, Teradata, etc.",
    },

    {
      description: "No SQL Database like MongoDB.",
    },

    {
      description: "Statistical programming.",
    },

    {
      description: "Machine learning.",
    },

    {
      description: "Research and develop statistical learning models for data analysis.",
    },

    {
      description: "Keep up-to-date with latest technology trends.",
    },

    {
      description: "Snowflake warehousing, architecture, processing, administration.",
    },
 
  ];

  const skills = [
    {
      description: "Data Analytics:  R, R Shiny, Splunk, Informatica, Python,SPSS Modeler.",
    },
    {
      description: "Web Technology - Frontend:  React, NextJs, Redux, Tailwind, Bootstrap.",
    },
    {
      description: "Web Technology - Backend: NodeJs,ExpressJs, Django, MERN (Full Stack).",
    },
    {
      description: "ETL and Data Analysis:  Snowflake, Informatica,Dataiku, SSIS.",
    },
    {
      description: "BI Tools: Cognos Analytics, PowerBI, SPLUNK.",
    },
    {
      description: "Cloud Technologies: AWS S3,AWS EC2, AWS SES, AWS Sagemaker.",
    },
    {
      description: "RDBMS:  DB2, SQL Server, Oracle.",
    },
    {
      description: "NoSQL:  MongoDB.",
    },
    {
      description: "Big Data: Hadoop, HBase, Hive, Spark.",
    },
    {
      description: "Project management: MS Project.",
    },
  
  ];

  const domains = [
    {
      description: "Hospitality     :	 Marketing, Sales, Resort Operations, Financial.",
    },
    {
      description: "Banking         :	 Retail, ATM, Switches, Mortgage Loan Processing, Customer Relationship.",
    },
    {
      description: "Actuary, underwriting, Product management, Claim Processing, Reinsurance.",
    },
    {
      description: "Capital Marketing, Derivatives, Surveillance, Technical Analysis.",
    },
     
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2 className="text-light ">CT Sevuga Perumal</h2>
                <p>A competitive professional conducting trainings on cutting edge technologies AND taking incharge of delivering full stack web application, business intelligence and data science projects. </p>
                <p>Looking for a position as a consultant who's responsibility is to take incharge/guide for a full fledged 
                  data science project from finalizing requirement scope, collecting data through ETL process, developing and 
                  maintaining features, training and evaluating modules and monitoring the production environment with proper 
                  deployed model and various inference methods for serving.

                </p>
                <br></br>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Synopsis</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Technical</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">IT Skills</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">Domain</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          synopsis.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          technical.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          skills.map((skill, index) => {
                            return (
                              <ProjectCard
                                key={index}
                               {...skill}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <Row>
                        {
                          domains.map((domain, index) => {
                            return (
                              <ProjectCard
                                key={index}
                               {...domain}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                   </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img className="background-image-right" src={colorSharp2}></img> */}
    </section>
  )
}

export default Projects;
