const backend = [
    {
      subject: "Relational Database",
      detail1: "Operational Database.",
      detail2: "RDBMS.",
      detail3: "Relations and Properties of a Relation.",
      detail4: "Data Integrity.",
      detail5: "Data model Schema and Instance.",
      detail6: "Database Languages in DBMS.",
      detail7: "Entity Relationship Diagram.",
      detail8: "Mapping Constraints.",
      detail9: "Keys.",
    },
    {
      subject: "MySQL",
      detail1: "Introduction to MySQL.",
      detail2: "Reasons for popularity.",
      detail3: "Installation of MySQL.",
      detail4: "Execution of SQL queries in MySQL Database.",
      
      
    },

    {
      subject: "NoSQL Database",
      detail1: "Introduction to NoSQL Database.",
      detail2: "Flexible Schema.",
      detail3: "Fast Queries due to the data model.",
      detail4: "Horizantal Scaling.",
      detail5: "Relational Database Vs NoSQL Database.",
      detail6: "RDBMS vs NoSQL: Data Modeling Example.",
      detail7: "When should NoSQL be used?",
     
      
    },

    {
      subject: "MongoDB",
      detail1: "Introduction to MongoDB.",
      detail2: "Installation of MongoDB.",
      detail3: "Inserting Single Document and Many Documents.",
      detail4: "Searching Single Document and Many Documents.",
      detail5: "Updating Single Document and Many Documents.",
      detail6: "Deleting Document.",
      detail7: "Insert Many Documents from a json file.",
      detail8: "Filtering and do sorting on the filtered data.",
      detail9: "Using Projections in Array.",
    },
    
    {
      subject: "Nodejs and Express ",
      detail1: "Building an extensive backend API with Node.js & Express.",
      detail2: "Create Express web servers and APIs.",
      detail3: "Designing and developing REST APIs performing CRUD operations.",
      detail4: "Extensive API testing with Postman.",
      detail5: "Protecting routes/endpoints with JWT (JSON Web Tokens).",
      detail6: "How to implement Authentication & Authorization.",
      detail7: "Persisting data using a database.",
      detail8: "Modelling entities for the persistence layer/",
      detail9: "Build a complete, beautiful & real-world application from start to finish.",
    },

    
  

  ];

export default backend