import {
  Navbar,
  Nav,
  Container,

} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";


const HeaderComponent = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand href="/feature">INFOWISDOM</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
          <LinkContainer to="/training">
              <Nav.Link>
                Training
                <span className="position-absolute"></span>
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to="/services">
              <Nav.Link>
                Services
                <span className="position-absolute"></span>
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/profile">
              <Nav.Link>
                Profile
                <span className="position-absolute"></span>
              </Nav.Link>
            </LinkContainer>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderComponent;

