// import ProfileNavBar from "../components/ProfileNavBar";

import Training from "../components/Training";


import "../assets/css/Profile.css";


const TrainingPage = () => {

  return (
    <>
    <Training/>
       
   </>
  );
};

export default TrainingPage;