import { Row, Card } from "react-bootstrap";
const TrainingCard = ({ subject, detail1,detail2,detail3,detail4,detail5,detail6,detail7,detail8,detail9 }) => {
  return (
    <Row >

<Card style={{ width: '18rem', height: '27rem',  margin: '10px', padding: '10px'}}>
      
      <Card.Body>
        <Card.Title>{subject}</Card.Title>
        <Card.Text>
        {detail1}
        </Card.Text>
        <Card.Text>
        {detail2}
        </Card.Text>
        <Card.Text>
        {detail3}
        </Card.Text>
        <Card.Text>
        {detail4}
        </Card.Text>
        <Card.Text>
        {detail5}
        </Card.Text>
        <Card.Text>
        {detail6}
        </Card.Text>
        <Card.Text>
        {detail7}
        </Card.Text>
        <Card.Text>
        {detail8}
        </Card.Text>
        <Card.Text>
        {detail9}
        </Card.Text>
        
      </Card.Body>
    </Card>
      

     </Row>
  )
}

export default TrainingCard;
