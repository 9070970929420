import { Row, Card } from "react-bootstrap";
const ServiceCard = ({ Industry,Application }) => {
  return (
    <Row >

<Card style={{  margin: '10px', padding: '10px'}}>
      
      <Card.Body>
        <Card.Title>{Industry}</Card.Title>
        <Card.Text>
        {Application}
        </Card.Text>
              
      </Card.Body>
    </Card>
      

     </Row>
  )
}

export default ServiceCard;
