import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import  TrainingCard  from "./TrainingCard";
import frontend from '../data/frontend'
import backend from '../data/backend'
import mern from '../data/mern'
// import 'animate.css';
import TrackVisibility from 'react-on-screen';

const Trainings = () => {

  
  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2 className="text-light text-center ">Web Development Training</h2>
                <hr/>
                <h3 className="text-light text-center">100 % Job Assistance is provided</h3>
                <hr/>
                <p className="text-light text-center">Job oriented training is offered on three seperate job profile carrier in Website and Web Application Development </p>
                <hr/>
                  <h6 className="text-light text-center">React - Front End Developer</h6>
                  <h6 className="text-light text-center">NodeJS, Mongo DB - Back End Developer </h6>
                  <h6 className="text-light text-center">MERN - Full Stack we Developer</h6>
                  <hr/>
                
                <p className="text-light ">The course covers the complete requirement for the job profile, 
                  participants are made to practice interview question, mock interviews are conducted, 
                  and complete guidance is provided for performing successfully in the interview.
                </p>
                <br></br>
                <h3 className="text-light text-center">Requirements</h3>
                <h3 className="text-light text-center">--------------------------------</h3>
                <br></br>
                <h6 className="text-light text-center">You should have a Laptop.</h6>
                <h6 className="text-light text-center">A basic grasp of web development concepts such as HTML, CSS, and web architecture.</h6>
                <h6 className="text-light text-center">Must understand and write bootstrap & JavaScript code.</h6>
                <h6 className="text-light text-center">Who are really ready to face the challenges.</h6>
                <hr/>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">React - Front End</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Nodejs - Back End</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">MERN - Full Stack </Nav.Link>
                    </Nav.Item>

                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          frontend.map((project, index) => {
                            return (
                              <Col sm={12} md={6} lg={4} xl={3}>
                              <TrainingCard
                                key={index}
                                {...project}
                                />
                                </Col>
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        {
                          backend.map((project, index) => {
                            return (
                              <Col sm={12} md={6} lg={4} xl={3}>
                              <TrainingCard
                                key={index}
                                {...project}
                                />
                                </Col>
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                      <h3 className="text-light text-center">Requirements</h3>
                      <h4 className="text-light text-center">The frontend & Backend trainings should have already been taken .</h4>
                        {
                          mern.map((project, index) => {
                            return (
                              <Col sm={12} md={6} lg={4} xl={3}>
                              <TrainingCard
                                key={index}
                                {...project}
                                />
                                </Col>
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>

                   </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img className="background-image-right" src={colorSharp2}></img> */}
    </section>
  )
}

export default Trainings;
