import { Row } from "react-bootstrap";
const ProjectCard = ({ title, description, imgUrl }) => {
  return (
    <Row >
      
        {/* <div className="proj-txtx"> */}
          <p>{description}</p>
        {/* </div> */}
     </Row>
  )
}

export default ProjectCard;
