// import ProfileNavBar from "../components/ProfileNavBar";

import Service from "../components/Service";


import "../assets/css/Profile.css";


const ServicePage = () => {

  return (
    <>
    <Service/>
       
   </>
  );
};

export default ServicePage;