import { BrowserRouter, Routes, Route } from "react-router-dom";

// components:
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";



// publicly available pages:
import ProfilePage from "./pages/ProfilePage";


import TrainingPage from "./pages/TrainingPage";
import ServicePage from "./pages/ServicePage";





function App() {
  return (
    <BrowserRouter>
      <HeaderComponent />
      <Routes>
        {/* publicly available routes: */}
        <Route path="/" element={<TrainingPage />} />
        <Route path="/training" element={<TrainingPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/services" element={<ServicePage />} />
        
     
        </Routes>
      <FooterComponent />
    </BrowserRouter>
  );
}

export default App;

