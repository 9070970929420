const rShiny = [
    {
      "Industry": "Finance",
      "Application": " R Shiny is extensively used in finance for interactive dashboards, risk management tools, portfolio analysis, and real-time data visualization. It enables financial analysts to create powerful tools for data analysis and decision-making.",
     },
     {
      "Industry": "Healthcare",
      "Application": "In healthcare, R Shiny is used for patient data visualization, healthcare analytics, medical imaging analysis, and clinical decision support systems. It helps healthcare professionals and researchers analyze complex data and trends.",
     },
     {
      "Industry": "Marketing and Advertising",
      "Application": "R Shiny is used to create interactive dashboards for market research, customer segmentation, campaign performance analysis, and social media analytics. It enables marketers to make data-driven decisions and track campaign effectiveness.",
     },
     {
      "Industry": "Education",
      "Application": "In education, R Shiny is used for creating interactive learning tools, data visualization for educational research, student performance analysis, and course evaluation systems. It helps educators and students engage with data in a meaningful way.",
     },
     {
      "Industry": "Manufacturing",
      "Application": "R Shiny is used in manufacturing for production planning, quality control, supply chain optimization, and predictive maintenance. It helps manufacturers improve efficiency and reduce downtime.",
     },
     {
      "Industry": "Government and Public Policy",
      "Application": "R Shiny is used in government and public policy for data-driven decision-making, public data visualization, policy analysis, and program evaluation. It enables policymakers to analyze complex data and assess the impact of policies.",
     }, 
     {
      "Industry": "Retail",
      "Application": "In retail, R Shiny is used for sales forecasting, inventory management, customer segmentation, and market basket analysis. It helps retailers optimize their operations and improve customer satisfaction.",
     },
     {
      "Industry": "Energy and Utilities",
      "Application": "R Shiny is used in the energy and utilities sector for energy consumption analysis, renewable energy planning, grid optimization, and predictive maintenance of infrastructure. It helps companies in this sector make informed decisions and reduce costs.",
     },
    

 
   ]

export default rShiny