// import ProfileNavBar from "../components/ProfileNavBar";
import Skills from "../components/Skills";
import Projects from "../components/Projects";
import OrganizationalExperience from "../components/OrganizationalExperience";
import AcademicQualification from "../components/AcademicQualification";

import "../assets/css/Profile.css";


const ProfilePage = () => {

  return (
    <>
    <Projects/>
    <br></br>
    <Skills/>
    <br></br>
    <OrganizationalExperience/>
    <br></br>
    <AcademicQualification/>
    
   </>
  );
};

export default ProfilePage;