const qualification = [
    {
      "Degree": "Master of Science (MS)",
      "Institution": "Birla Institute of Technologies (BITS)",
      "Year": "2005",
     },
     {
        "Degree": "PGDCA - Post Graduate Deploma in Computer Application",
        "Institution": "SA College, Palayamkottai ,MS University Tirunelveli",
        "Year": "1992",
       },
       {
        "Degree": "BE(Mechanical)",
        "Institution": "ACCE&T, Karaikudi, M.K. University Madurai",
        "Year": "1990",
       },  
 
   ]

export default qualification