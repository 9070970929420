const experience = [
  {
    "Tenure": "Oct 23 – Present",
    "CompanyName": "Infowisdom",
   },
    {
      "Tenure": "Jun 22 – Aug 23",
      "CompanyName": "INDEN Academy",
     },
     {
      "Tenure": "May 20 – May 22",
      "CompanyName": "Freelance Data Scientist",
     },
     {
      "Tenure": "Sep 19 – Mar 20",
      "CompanyName": "Sterling Holidays, Chennai",
     },
     {
      "Tenure": "Apr 16– Apr 19",
      "CompanyName": "FIS Global Business Solutions, Chennai",
     },
     {
      "Tenure": "May 14  - Jun 15",
      "CompanyName": "Encore Software Services, Chennai",
     },
     {
      "Tenure": "Jul 07 –Oct 13",
      "CompanyName": "Computer Science Corporation, Chennai",
     }, {
      "Tenure": "May 06 – Jul  07",
      "CompanyName": "Accenture, Chennai",
     },
     {
      "Tenure": "Aug 04 – Apr  06",
      "CompanyName": "Cognizant Technology Solutions, Chennai",
     },
     {
      "Tenure": "Jun 00 – Aug  04",
      "CompanyName": "efunds international India ltd, Chennai, India",
     },
     {
      "Tenure": "Aug’97 – May 00",
      "CompanyName": "Vivek Softwares, Chennai",
     },

 
   ]

export default experience