
const Skills = () => {
 

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h4>Academic Qualification</h4>
                        <p>Master of Science (MS), Birla Institute of Technologies (BITS), 2005.
                        <br></br> 
                        PGDCA,SA College, Palayamkottai ,MS University Tirunelveli, 1992.
                        <br></br> 
                        BE(Mechanical), ACCE&T, Karaikudi, M.K. University Madurai, 1990
                        </p>
                        <h4>Certifications</h4>
                        <p>Microsoft Certified: Data Analyst Associate, 2021
                        <br></br> 
                        Google Analytics Individual Qualification _ Google, 2021
                        <br></br> 
                        AWS Certified Solution Architect – Associate, 2019
                        <br></br> 
                        NCFM Technical Analysis Module, 2018
                        <br></br> 
                        Developer Certification for Apache Spark (Databricks and O’reilly), 2016
                        <br></br> 
                        Microsoft Specialist: Managing Projects with Microsoft Project 2013, 2015
                        <br></br> 
                        Cloudera [ CCD – 410 ]  Hadoop Developer, 2014
                        <br></br> 
                        ALMI (Associate, Life Management Institute) From LOMA, 2009
                        <br></br> 
                        5 NSE certificates on Financial Marketing
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
  )
}

export default Skills;
